import {all, fork} from 'redux-saga/effects';
import auth from 'redux/sagas/auth';
import common from 'redux/sagas/common';
import instructor from 'redux/sagas/instructor';
import inspection from 'redux/sagas/inspection';
import student from 'redux/sagas/student';
import flight from 'redux/sagas/flight';
import drone from 'redux/sagas/drone';

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(common),
    fork(instructor),
    fork(inspection),
    fork(student),
    fork(drone),
    fork(flight),
  ]);
}
