import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/droneActions';
import {
  GetDroneList,
  GetFlightLogList,
  CreateDrone,
  UpdateDrone
} from 'api';

function* getDroneList(action) {
  try {
    const res = yield call(GetDroneList, action.data);
    yield put({type: types.getDroneListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getDroneListFail().type, err});
  }
}

function* getFlightLogList(action) {
  try {
    const res = yield call(GetFlightLogList, action.data);
    yield put({type: types.getFlightLogListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getFlightLogListFail().type, err});
  }
}

function* createDrone(action) {
  try {
    const res = yield call(CreateDrone, action.data);
    yield put({type: types.createDroneSuccess().type, payload: res});
    yield getDroneList({});
  } catch (err) {
    yield put({type: types.createDroneFail().type, err});
  }
}

function* updateDrone(action) {
  try {
    const res = yield call(UpdateDrone, action.data);
    yield put({type: types.updateDroneSuccess().type, payload: res});
    yield getDroneList({});
  } catch (err) {
    yield put({type: types.updateDroneFail().type, err});
  }
}

function* watchGetDroneList() {
  yield takeEvery(types.getDroneListRequest().type, getDroneList);
}


function* watchGetFlightLogList() {
  yield takeEvery(types.getFlightLogListRequest().type, getFlightLogList);
}

function* watchCreateDrone() {
  yield takeEvery(types.createDroneRequest().type, createDrone);
}

function* watchUpdateDrone() {
  yield takeEvery(types.updateDroneRequest().type, updateDrone);
}


export default function* rootSage() {
  yield all([
    fork(watchGetDroneList),
    fork(watchGetFlightLogList),
    fork(watchCreateDrone),
    fork(watchUpdateDrone),
  ]);
}
