import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/studentActions';
import {
  GetStudentList,
  GetFlightCareerList
} from 'api';

function* getStudentList(action) {
  try {
    const res = yield call(GetStudentList, action.data);
    yield put({type: types.getStudentListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getStudentListFail().type, err});
  }
}

function* getFlightCareerList(action) {
  try {
    const res = yield call(GetFlightCareerList, action.data);
    yield put({type: types.getFlightCareerSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getFlightCareerFail().type, err});
  }
}

function* watchGetStudentList() {
  yield takeEvery(types.getStudentListRequest().type, getStudentList);
}

function* watchGetFlightCareerList() {
  yield takeEvery(types.getFlightCareerRequest().type, getFlightCareerList);
}

export default function* rootSage() {
  yield all([
    fork(watchGetStudentList),
    fork(watchGetFlightCareerList),
  ]);
}
