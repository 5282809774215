export const createInstructorRequest = (params) => {
  return {
    type: 'CREATE_INSTRUCTOR_REQUEST',
    data: params,
  };
};
export const createInstructorSuccess = (data) => {
  return {
    type: 'CREATE_INSTRUCTOR_SUCCESS',
    data
  };
};
export const createInstructorFail = (error) => {
  return {
    type: 'CREATE_INSTRUCTOR_FAIL',
    error,
  };
};

export const updateInstructorRequest = (params) => {
  return {
    type: 'UPDATE_INSTRUCTOR_REQUEST',
    data: params,
  };
};
export const updateInstructorSuccess = (data) => {
  return {
    type: 'UPDATE_INSTRUCTOR_SUCCESS',
    data
  };
};
export const updateInstructorFail = (error) => {
  return {
    type: 'UPDATE_INSTRUCTOR_FAIL',
    error,
  };
};

export const getInstructorListRequest = (params) => {
  return {
    type: 'GET_INSTRUCTOR_LIST_REQUEST',
    data: params,
  };
};
export const getInstructorListSuccess = (data) => {
  return {
    type: 'GET_INSTRUCTOR_LIST_SUCCESS',
    data
  };
};
export const getInstructorListFail = (error) => {
  return {
    type: 'GET_INSTRUCTOR_LIST_FAIL',
    error,
  };
};
