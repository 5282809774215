import {Suspense, useCallback, useEffect} from 'react';
import Login from 'pages/Login';
import Main from 'pages/Main';
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {refreshRequest} from 'redux/actions/authActions';

function App() {
  const dispatch = useDispatch();
  const {userData} = useSelector(({auth}) => auth, shallowEqual);
  const refresh = useCallback(() => {
    dispatch(refreshRequest());
  }, [dispatch])
  useEffect(() => {
    refresh();
  }, []);
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        {userData ? <Main userData={userData}/> : <Login/>}
      </Suspense>
    </div>
  );
}

export default App;
