import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/inspectionActions';
import {
  GetInspectionList,
  CreateInspection,
  UpdateInspection
} from 'api';

function* getInspectionList(action) {
  try {
    const res = yield call(GetInspectionList, action.data);
    yield put({type: types.getInspectionSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getInspectionFail().type, err});
  }
}

function* createInspection(action) {
  try {
    const res = yield call(CreateInspection, action.data);
    yield put({type: types.createInspectionSuccess().type, payload: res});
    yield getInspectionList({
      data: {
        inspmt: action.data.inspmt,
        droneid: action.data.droneid
      }
    });
  } catch (err) {
    yield put({type: types.createInspectionFail().type, err});
  }
}

function* updateInspection(action) {
  try {
    const res = yield call(UpdateInspection, action.data);
    yield put({type: types.updateInspectionSuccess().type, payload: res});
    yield getInspectionList({
      data: {
        inspmt: action.data.inspmt,
        droneid: action.data.droneid
      }
    });
  } catch (err) {
    yield put({type: types.updateInspectionFail().type, err});
  }
}

function* watchGetInspectionList() {
  yield takeEvery(types.getInspectionRequest().type, getInspectionList);
}

function* watchCreateInspection() {
  yield takeEvery(types.createInspectionRequest().type, createInspection);
}

function* watchUpdateInspection() {
  yield takeEvery(types.updateInspectionRequest().type, updateInspection);
}

export default function* rootSage() {
  yield all([
    fork(watchGetInspectionList),
    fork(watchCreateInspection),
    fork(watchUpdateInspection),
  ]);
}
