export const getInspectionRequest = (params) => {
  return {
    type: 'GET_INSPECTION_REQUEST',
    data: params,
  };
};
export const getInspectionSuccess = (data) => {
  return {
    type: 'GET_INSPECTION_SUCCESS',
    data
  };
};
export const getInspectionFail = (error) => {
  return {
    type: 'GET_INSPECTION_FAIL',
    error,
  };
};

export const createInspectionRequest = (params) => {
  return {
    type: 'CREATE_INSPECTION_REQUEST',
    data: params,
  };
};
export const createInspectionSuccess = (data) => {
  return {
    type: 'CREATE_INSPECTION_SUCCESS',
    data
  };
};
export const createInspectionFail = (error) => {
  return {
    type: 'CREATE_INSPECTION_FAIL',
    error,
  };
};


export const updateInspectionRequest = (params) => {
  return {
    type: 'UPDATE_INSPECTION_REQUEST',
    data: params,
  };
};
export const updateInspectionSuccess = (data) => {
  return {
    type: 'UPDATE_INSPECTION_SUCCESS',
    data
  };
};
export const updateInspectionFail = (error) => {
  return {
    type: 'UPDATE_INSPECTION_FAIL',
    error,
  };
};
