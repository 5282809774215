import React from 'react';
import Select from 'react-select';

const customStyles = ({border}) => ({
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#5151F6',
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 20,
    boxShadow: border ? '0 0 0 1px #aaaaaa' : 0,
    border: state.menuIsOpen ? '1px solid #5151F6' : 0,
  }),
  input: (provided, state) => ({
    ...provided,
    width: 120,
  }),
  option: (provided, {isSelected}) => ({
    ...provided,
    backgroundColor: isSelected && '#A4A4FD',
    ':hover': {
      backgroundColor: '#A4A4FD',
      color: 'white',
    },
    ':active': {
      backgroundColor: '#A4A4FD',
      color: 'white',
    },
  }),
});

export default function SelectBox({
                                    style,
                                    value,
                                    options,
                                    onChange,
                                    placeholder = '선택',
                                    border,
                                    name,
                                    isMulti,
                                    defaultValue,
                                    closeMenuOnSelect,
                                    className,
                                    disabled
                                  }) {
  return (
    <div style={style}>
      <Select
        className={className}
        name={name}
        options={options}
        styles={customStyles({border})}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled ={disabled}
      />
    </div>
  );
}
