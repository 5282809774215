import * as types from 'redux/actions/commonActions';

const commonState = {
  status: 'DONE',
  agcyList: [],
  typeList: {},
};

const common = (state = commonState, action) => {
  const {payload, type} = action;
  switch (type) {
    case types.getAgcyListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getAgcyListSuccess().type:
      return {...state, status: 'DONE', agcyList: payload.data.data};
    case types.getAgcyListFail().type:
      return {...state, status: 'DONE'};
    case types.getTypeListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getTypeListSuccess().type:
      const setObj = {};
      if (payload.data.data && payload.data.data.length > 0) {
        payload.data.data.forEach((itm)=>{
          if (setObj[itm.typecd]) {
            setObj[itm.typecd].push({
              value:itm.subcd,
              label:itm.subnm,
              extra1:itm.extra1,
            });
          } else {
            setObj[itm.typecd] = [{
              value:itm.subcd,
              label:itm.subnm,
              extra1:itm.extra1,
            }];
          }
        })
      }
      return {...state, status: 'DONE', typeList: setObj};
    case types.getTypeListFail().type:
      return {...state, status: 'DONE'};
    default:
      return state;
  }
};

export default common;
