export const getDroneListRequest = (params) => {
  return {
    type: 'GET_DRONE_LIST_REQUEST',
    data: params,
  };
};

export const getDroneListSuccess = (data) => {
  return {
    type: 'GET_DRONE_LIST_SUCCESS',
    data
  };
};

export const getDroneListFail = (error) => {
  return {
    type: 'GET_DRONE_LIST_FAIL',
    error,
  };
};

export const getGcsDroneListRequest = (params) => {
  return {
    type: 'GET_GCS_DRONE_LIST_REQUEST',
    data: params,
  };
};

export const getGcsDroneListSuccess = (data) => {
  return {
    type: 'GET_GCS_DRONE_LIST_SUCCESS',
    data
  };
};

export const getGcsDroneListFail = (error) => {
  return {
    type: 'GET_GCS_DRONE_LIST_FAIL',
    error,
  };
};

export const createDroneRequest = (params) => {
  return {
    type: 'CREATE_DRONE_REQUEST',
    data: params,
  };
};

export const createDroneSuccess = (data) => {
  return {
    type: 'CREATE_DRONE_SUCCESS',
    data
  };
};

export const createDroneFail = (error) => {
  return {
    type: 'CREATE_DRONE_FAIL',
    error,
  };
};

export const updateDroneRequest = (params) => {
  return {
    type: 'UPDATE_DRONE_REQUEST',
    data: params,
  };
};

export const updateDroneSuccess = (data) => {
  return {
    type: 'UPDATE_DRONE_SUCCESS',
    data
  };
};

export const updateDroneFail = (error) => {
  return {
    type: 'UPDATE_DRONE_FAIL',
    error,
  };
};

export const getFlightLogListRequest = (params) => {
  return {
    type: 'GET_FLIGHT_LOG_LIST_REQUEST',
    data: params,
  };
};

export const getFlightLogListSuccess = (data) => {
  return {
    type: 'GET_FLIGHT_LOG_LIST_SUCCESS',
    data
  };
};

export const getFlightLogListFail = (error) => {
  return {
    type: 'GET_FLIGHT_LOG_LIST_FAIL',
    error,
  };
};

export const resetDrone = () => {
  return {
    type: 'RESET_DRONE',
  };
};
