import * as types from 'redux/actions/studentActions';
import Toast from 'components/Toast';

const studentState = {
  status: 'DONE',
  data: {},
  listData: [],
  careerList: {},
};

const student = (state = studentState, action) => {
  const {payload, type} = action;
  switch (type) {
    case types.getStudentListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getStudentListSuccess().type:
      return {...state, status: 'DONE', listData: payload.data.data};
    case types.getStudentListFail().type:
      return {...state, status: 'DONE'};
    case types.getFlightCareerRequest().type:
      return {...state, status: 'PENDING'};
    case types.getFlightCareerSuccess().type:
      return {...state, status: 'CAREER', careerList: payload.data.data};
    case types.getFlightCareerFail().type:
      return {...state, status: 'DONE'};
    case types.resetStudent().type:
      return {...studentState};
    default:
      return state;
  }
};

export default student;
