export const loginRequest = (params) => {
  return {
    type: 'LOGIN_REQUEST',
    data: params,
  };
};

export const loginSuccess = (data) => {
  return {
    type: 'LOGIN_SUCCESS',
    data
  };
};

export const loginFail = (error) => {
  return {
    type: 'LOGIN_FAIL',
    error,
  };
};

export const logOutRequest = (params) => {
  return {
    type: 'LOGOUT_REQUEST',
    data: params,
  };
};

export const logOutSuccess = (data) => {
  return {
    type: 'LOGOUT_SUCCESS',
    data
  };
};

export const logOutFail = (error) => {
  return {
    type: 'LOGOUT_FAIL',
    error,
  };
};

export const refreshRequest = (params) => {
  return {
    type: 'REFRESH_REQUEST',
    data: params,
  };
};

export const refreshSuccess = (data) => {
  return {
    type: 'REFRESH_SUCCESS',
    data
  };
};

export const refreshFail = (error) => {
  return {
    type: 'REFRESH_FAIL',
    error,
  };
};
