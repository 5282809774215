import React from 'react';
import classNames from 'classnames/bind';
import styles from 'styles/components/Button.module.scss';
import {MdAdd} from 'react-icons/md';

const cx = classNames.bind(styles);

export default function Button(props) {
  return (
    <div
      className={cx(['container', props.type])}
      onClick={props.onClick}
      style={{
        ...props.styles,
        width: props.width,
      }}>
      {props.icon && <MdAdd className={cx('icon')}/>}
      {props.title}
    </div>
  );
}
