import React, {useState, useRef, useEffect, useCallback} from 'react';
import classNames from 'classnames/bind';
import {MdCheckCircle} from 'react-icons/md';
import Logo from 'assets/logo.png';
import Button from 'components/Button';
import styles from 'styles/Login.module.scss';
import {getAgcyListRequest} from "../redux/actions/commonActions";
import {loginRequest} from "../redux/actions/authActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Select from 'components/Select';
import Toast from 'components/Toast';
import {useTranslation} from 'react-i18next';
import errorCode from 'config/errorCode';

const cx = classNames.bind(styles);

export default function Login() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const getAgcyList = useCallback(() => {
    dispatch(getAgcyListRequest());
  }, [dispatch]);
  const {agcyList, errCode} = useSelector(({auth, common}) => ({
    agcyList: common.agcyList,
    errCode: auth.errCode,
  }), shallowEqual);
  const agcyOptions = agcyList.map((itm) => ({value: itm.agcy, label: itm.agcynm}))
  const [agcy, setAgcy] = useState(null);
  const [state, setState] = useState({
    teacid: '',
    teacpwd: '',
    isSaveId: false,
    isSavePassword: false,
  });
  const usernameRef = useRef();
  const passwordRef = useRef();
  const onChangeAgcy = ({value}) => {
    setAgcy(value);
  }
  // 화면 로딩 후, 아이디 입력 필드 포커스
  useEffect(() => {
    getAgcyList();
    usernameRef.current.focus();
    const savedId = window.localStorage.getItem('@username');
    // 저장된 아이디 존재 시
    if (savedId) {
      setState({
        teacid: savedId,
        isSaveId: true,
      });
      passwordRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveId = () => {
    setState({
      ...state,
      isSaveId: !state.isSaveId,
    });
  };

  // 로그인
  const doLogin = () => {
    if (!agcy) {
      Toast.warning('교육기관을 선택해주세요');
      return;
    }
    if (!state.teacid) {
      Toast.warning('아이디를 입력해주세요');
      return;
    }
    if (!state.teacpwd) {
      Toast.warning('비밀번호를 입력해주세요');
      return;
    }
    if (state.isSaveId) {
      window.localStorage.setItem('@username', state.teacid);
    } else {
      const savedId = window.localStorage.getItem('@username');
      if (savedId) {
        window.localStorage.removeItem('@username');
      }
    }
    console.log(state);
    dispatch(loginRequest({
      ...state,
      agcy
    }));
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'teacid':
          passwordRef.current.focus();
          break;
        case 'teacpwd':
          doLogin();
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className={cx('container')}>
      <div className={cx('overlay')}/>
      <div className={cx('contentWrap')}>
        <div className={cx('loginLogo')}>
          <img src={Logo} alt="logo"/>
        </div>
        <div className={cx('content')}>
          <h2 className={cx('title')}>Log in</h2>
          <form className={cx('fieldsWrapper')}>
            <Select
              className={cx('select')}
              options={agcyOptions}
              onChange={onChangeAgcy}
              border={true}
              placeholder={'교육기관 선택'}
            />
            <input
              ref={usernameRef}
              name="teacid"
              type="text"
              value={state.teacid}
              placeholder={'아이디'}
              onChange={handleChange}
              onKeyPress={pressEnter}
              className="field"
              autoComplete="teacid"
            />
            <input
              ref={passwordRef}
              name="teacpwd"
              type="password"
              value={state.password}
              placeholder={'비밀번호'}
              onChange={handleChange}
              onKeyPress={pressEnter}
              className="field"
              autoComplete="current-password"
              style={{marginTop: 15}}
            />
            <div className={cx(['checkbox', {selected: state.isSaveId}])} onClick={handleSaveId}>
              <MdCheckCircle/>
              아이디저장
            </div>
          </form>
          <Button width={220} title={'로그인'} onClick={doLogin}/>
          {errCode && <div className={cx('errorWrap')}>{t(errorCode[errCode].message)}</div>}
        </div>
      </div>
    </div>
  );
}
