import * as types from 'redux/actions/inspectionActions';
import Toast from 'components/Toast';

const inspectionState = {
  status: 'DONE',
  data: {},
  listData: [],
};

const inspection = (state = inspectionState, action) => {
  const {payload, type} = action;
  switch (type) {
    case types.getInspectionRequest().type:
      return {...state, status: 'PENDING'};
    case types.getInspectionSuccess().type:
      return {...state, status: 'DONE', listData: payload.data.data};
    case types.getInspectionFail().type:
      return {...state, status: 'DONE'};
    case types.createInspectionRequest().type:
      return {...state, status: 'PENDING'};
    case types.createInspectionSuccess().type:
      Toast.success('저장되었습니다.');
      return {...state, status: 'DONE'};
    case types.createInspectionFail().type:
      return {...state, status: 'DONE'};
    case types.updateInspectionRequest().type:
      return {...state, status: 'PENDING'};
    case types.updateInspectionSuccess().type:
      Toast.success('수정되었습니다.');
      return {...state, status: 'DONE'};
    case types.updateInspectionFail().type:
      return {...state, status: 'DONE'};
    default:
      return state;
  }
};

export default inspection;
