import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/instructorActions';
import {
  CreateInstructor,
  UpdateInstructor,
  GetInstructorList
} from 'api';

function* createInstructor(action) {
  try {
    const res = yield call(CreateInstructor, action.data);
    yield put({type: types.createInstructorSuccess().type, payload: res});
    yield getInstructorList({});
  } catch (err) {
    yield put({type: types.createInstructorFail().type, err});
  }
}

function* updateInstructor(action) {
  try {
    const res = yield call(UpdateInstructor, action.data);
    yield put({type: types.updateInstructorSuccess().type, payload: res});
    yield getInstructorList({});
  } catch (err) {
    yield put({type: types.updateInstructorFail().type, err});
  }
}

function* getInstructorList(action) {
  try {
    const res = yield call(GetInstructorList, action.data);
    yield put({type: types.getInstructorListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getInstructorListFail().type, err});
  }
}

function* watchCreateInstructor() {
  yield takeEvery(types.createInstructorRequest().type, createInstructor);
}

function* watchGetInstructorList() {
  yield takeEvery(types.getInstructorListRequest().type, getInstructorList);
}

function* watchUpdateInstructor() {
  yield takeEvery(types.updateInstructorRequest().type, updateInstructor);
}

export default function* rootSage() {
  yield all([
    fork(watchCreateInstructor),
    fork(watchUpdateInstructor),
    fork(watchGetInstructorList),
  ]);
}
