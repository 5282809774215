export const getFlightListRequest = (params) => {
  return {
    type: 'GET_FLIGHT_LIST_REQUEST',
    data: params,
  };
};

export const getFlightListSuccess = (data) => {
  return {
    type: 'GET_FLIGHT_LIST_SUCCESS',
    data
  };
};

export const getFlightListFail = (error) => {
  return {
    type: 'GET_FLIGHT_LIST_FAIL',
    error,
  };
};

export const getFlightCheckListRequest = (params) => {
  return {
    type: 'GET_FLIGHT_CHECK_LIST_REQUEST',
    data: params,
  };
};

export const getFlightCheckListSuccess = (data) => {
  return {
    type: 'GET_FLIGHT_CHECK_LIST_SUCCESS',
    data
  };
};

export const getFlightCheckListFail = (error) => {
  return {
    type: 'GET_FLIGHT_CHECK_LIST_FAIL',
    error,
  };
};

export const getFlightTrainListRequest = (params) => {
  return {
    type: 'GET_FLIGHT_TRAIN_LIST_REQUEST',
    data: params,
  };
};

export const getFlightTrainListSuccess = (data) => {
  return {
    type: 'GET_FLIGHT_TRAIN_LIST_SUCCESS',
    data
  };
};

export const getFlightTrainListFail = (error) => {
  return {
    type: 'GET_FLIGHT_TRAIN_LIST_FAIL',
    error,
  };
};

export const saveFlightTrainRequest = (params) => {
  return {
    type: 'SAVE_FLIGHT_TRAIN_REQUEST',
    data: params,
  };
};

export const saveFlightTrainSuccess = (data) => {
  return {
    type: 'SAVE_FLIGHT_TRAIN_SUCCESS',
    data
  };
};

export const saveFlightTrainFail = (error) => {
  return {
    type: 'SAVE_FLIGHT_TRAIN_FAIL',
    error,
  };
};

export const resetFlight = () => {
  return {
    type: 'RESET_FLIGHT',
  };
};
