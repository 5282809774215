import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from 'styles/components/Toast.module.scss';
import classNames from 'classnames/bind';
import { MdCancel, MdCheckCircle } from 'react-icons/md';

const cx = classNames.bind(styles);

const config = {
  autoClose: 2000,
  className: cx('toast'),
  bodyClassName: cx('toast_body'),
};

const toasts = {
  success: (msg) =>
    toast.success(
      <div className={cx('toast_content')}>
        {msg}
      </div>,
      config,
    ),
  error: (msg) =>
    toast.error(
      <div className={cx('toast_content')}>
        {msg}
      </div>,
      config,
    ),
  warning: (msg) =>
    toast.warning(
      <div className={cx('toast_content')}>
        {msg}
      </div>,
      config,
    ),
};
export default toasts;
