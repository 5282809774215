import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import {Login, LogOut, Refresh} from "../../api";
import * as types from "../actions/authActions";

function* login(action) {
  try {
    const res = yield call(Login, action.data);
    yield put({type: types.loginSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.loginFail().type, err});
  }
}

function* logout(action) {
  try {
    const res = yield call(LogOut, action.data);
    yield put({type: types.logOutSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.logOutFail().type, err});
  }
}

function* refresh(action) {
  try {
    const res = yield call(Refresh, action.data);
    yield put({type: types.refreshSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.refreshFail().type, err});
  }
}

function* watchLogin() {
  yield takeEvery(types.loginRequest().type, login);
}

function* watchLogout() {
  yield takeEvery(types.logOutRequest().type, logout);
}

function* watchRefresh() {
  yield takeEvery(types.refreshRequest().type, refresh);
}

export default function* rootSage() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRefresh),
  ]);
}
