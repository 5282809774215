import classNames from 'classnames/bind';
import styles from 'styles/components/NavBar.module.scss';
import {useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {
  logOutRequest,
} from 'redux/actions/authActions';

const cx = classNames.bind(styles);
export default function NavBar({userData}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const onLogout = () => {
    dispatch(logOutRequest());
  }
  return (
    <div className={cx('container')}>
      <h3>{location.state && location.state.title}</h3>
      <div className={cx('user_info')}>
        <h3>{userData.teacnm || userData.name}</h3> <span>님</span>
        <button className={cx('logout')} onClick={onLogout}>로그아웃</button>
      </div>
    </div>
  );
}
