import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/commonActions';
import {
  GetAgcyList,
  GetTypeList
} from 'api';

function* getAgcyList(action) {
  try {
    const res = yield call(GetAgcyList, action.data);
    yield put({type: types.getAgcyListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getAgcyListFail().type, err});
  }
}

function* getTypeList(action) {
  try {
    const res = yield call(GetTypeList, action.data);
    yield put({type: types.getTypeListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getTypeListFail().type, err});
  }
}

function* watchGetAgcyList() {
  yield takeEvery(types.getAgcyListRequest().type, getAgcyList);
}

function* watchGetTypeList() {
  yield takeEvery(types.getTypeListRequest().type, getTypeList);
}

export default function* rootSage() {
  yield all([
    fork(watchGetAgcyList),
    fork(watchGetTypeList),
  ]);
}
