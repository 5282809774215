import * as types from 'redux/actions/flightActions';
import Toast from 'components/Toast';

const flightState = {
  status: 'DONE',
  data: {},
  listData: [],
  checkList:[],
  trainList:[]
};

const flight = (state = flightState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.getFlightListRequest().type:
      return { ...state, status: 'PENDING' };
    case types.getFlightListSuccess().type:
      return { ...state, status: 'DONE', listData: payload.data.data };
    case types.getFlightListFail().type:
      return { ...state, status: 'DONE' };
    case types.getFlightCheckListRequest().type:
      return { ...state, status: 'PENDING' };
    case types.getFlightCheckListSuccess().type:
      return { ...state, status: 'CHECKED', checkList: payload.data.data };
    case types.getFlightCheckListFail().type:
      return { ...state, status: 'DONE' };
    case types.getFlightTrainListRequest().type:
      return { ...state, status: 'PENDING' };
    case types.getFlightTrainListSuccess().type:
      return { ...state, status: 'DONE', trainList: payload.data.data };
    case types.getFlightTrainListFail().type:
      return { ...state, status: 'DONE' };
    case types.saveFlightTrainRequest().type:
      return { ...state, status: 'PENDING' };
    case types.saveFlightTrainSuccess().type:
      Toast.success('저장되었습니다.');
      return { ...state, status: 'DONE' };
    case types.saveFlightTrainFail().type:
      return { ...state, status: 'DONE' };
    case types.resetFlight().type:
      return { ...flightState };
    default:
      return state;
  }
};

export default flight;
