import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from 'redux/configureStore';
import App from 'pages/App';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import 'styles/globals.scss';
import "react-datepicker/dist/react-datepicker.css";
import {locale} from 'moment';
import 'moment/locale/ko';
import {ToastContainer} from 'react-toastify';

import './lang/i18n';

const customHistory = createBrowserHistory();
const store = configureStore(customHistory);
locale('ko');
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <App/>
        <ToastContainer style={{width: 'auto'}}/>
      </React.StrictMode>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

