import {Route, Routes} from 'react-router-dom';
import {
  InstructorPage,
  StudentPage,
  DronePage,
  FlightPage,
  InspectionPage
} from 'pages';
import Menu from 'components/Menu';
import NavBar from 'components/NavBar';
import classNames from 'classnames/bind';
import styles from 'styles/Main.module.scss';

const cx = classNames.bind(styles);

export default function Main({userData}) {
  return (
    <div className={cx('container')}>
      <div className={cx('navbar')}>
        <NavBar userData = {userData}/>
      </div>
      <div className={cx('menu')}>
        <Menu/>
      </div>
      <div className={cx('content_wrap')}>
        <Routes>
          <Route path="/" element={<InstructorPage/>}/>
          <Route path="/student" element={<StudentPage/>}/>
          <Route path="/drone" element={<DronePage/>}/>
          <Route path="/flight" element={<FlightPage/>}/>
          <Route path="/inspection" element={<InspectionPage/>}/>
        </Routes>
      </div>
    </div>
  );
}
