const errCode = {
  "500": "아이디를 입력해주세요",
  "501": "비밀번호를 입력해주세요",
  "502": "이름을 입력해주세요",
  "503": "자격번호를 입력해주세요",
  "600": "신고번호를 입력해주세요",
  "601": "기본시간을 입력해주세요",
};

export default errCode;
