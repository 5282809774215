import * as types from 'redux/actions/droneActions';
import Toast from 'components/Toast';
import errCode from "../../config/errorCode";

const droneState = {
  status: 'DONE',
  data: {},
  gcsDroneList: [],
  listData: [],
  logList: [],
};

const drone = (state = droneState, action) => {
  const {payload, type} = action;
  switch (type) {
    case types.getDroneListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getDroneListSuccess().type:
      return {...state, status: 'DONE', listData: payload.data.data};
    case types.getDroneListFail().type:
      return {...state, status: 'DONE'};
    case types.getGcsDroneListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getGcsDroneListSuccess().type:
      return {...state, status: 'DONE', gcsDroneList: payload.data.data};
    case types.getGcsDroneListFail().type:
      return {...state, status: 'DONE'};
    case types.createDroneRequest().type:
      return {...state, status: 'PENDING'};
    case types.createDroneSuccess().type:
      if (payload.data.success) {
        Toast.success('저장되었습니다.');
        return { ...state, status: 'CREATED'};
      } else {
        Toast.error(errCode[payload.data.errcd]);
        return { ...state, status: 'DONE'};
      }
    case types.createDroneFail().type:
      return {...state, status: 'DONE'};
    case types.updateDroneRequest().type:
      return {...state, status: 'PENDING'};
    case types.updateDroneSuccess().type:
      if (payload.data.success) {
        Toast.success('저장되었습니다.');
        return { ...state, status: 'CREATED'};
      } else {
        Toast.error(errCode[payload.data.errcd]);
        return { ...state, status: 'DONE'};
      }
    case types.updateDroneFail().type:
      return {...state, status: 'DONE'};
    case types.getFlightLogListRequest().type:
      return {...state, status: 'PENDING'};
    case types.getFlightLogListSuccess().type:
      return {...state, status: 'LOG', logList: payload.data.data};
    case types.getFlightLogListFail().type:
      return {...state, status: 'DONE'};
    case types.resetDrone().type:
      return {...droneState};
    default:
      return state;
  }
};

export default drone;
