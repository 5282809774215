import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import langEn from './lang.en';
import langKo from './lang.ko';

const resources = {
  en: {
    translation: langEn
  },
  ko: {
    translation: langKo
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko",
  });

export default i18n;
