import * as types from 'redux/actions/instructorActions';
import Toast from 'components/Toast';
import errCode from 'config/errorCode';

const instructorState = {
  status: 'DONE',
  data: {},
  listData: [],
};

const instructor = (state = instructorState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.createInstructorRequest().type:
      return { ...state, status: 'PENDING' };
    case types.createInstructorSuccess().type:
      if (payload.data.success) {
        Toast.success('저장되었습니다.');
        return { ...state, status: 'CREATED'};
      } else {
        Toast.error(errCode[payload.data.errcd]);
        return { ...state, status: 'DONE'};
      }
    case types.createInstructorFail().type:
      Toast.error('저장에 실패하였습니다.');
      return { ...state, status: 'DONE' };
    case types.getInstructorListRequest().type:
      return { ...state, status: 'PENDING' };
    case types.getInstructorListSuccess().type:
      return { ...state, status: 'DONE', listData: payload.data.data };
    case types.getInstructorListFail().type:
      return { ...state, status: 'DONE' };
    case types.updateInstructorRequest().type:
      return { ...state, status: 'PENDING' };
    case types.updateInstructorSuccess().type:
      if (payload.data.success) {
        Toast.success('저장되었습니다.');
        return { ...state, status: 'CREATED'};
      } else {
        Toast.error(errCode[payload.data.errcd]);
        return { ...state, status: 'DONE'};
      }
    case types.updateInstructorFail().type:
      Toast.error('저장에 실패하였습니다.');
      return { ...state, status: 'DONE' };
    default:
      return state;
  }
};

export default instructor;
