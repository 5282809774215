import * as types from 'redux/actions/authActions';
import Toast from 'components/Toast';

const authState = {
  status: 'DONE',
  userData: null,
  errCode: '',
};

const auth = (state = authState, action) => {
  const {payload, type} = action;
  switch (type) {
    case types.loginRequest().type:
      return {...state, status: 'PENDING'};
    case types.loginSuccess().type:
      if (payload.data.success) {
        return {...state, status: 'DONE', userData: payload.data.data};
      } else {
        Toast.error('아이디 혹은 비밀번호를 확인해주세요');
        return {...state, status: 'DONE'};
      }
    case types.loginFail().type:
      return {...state, status: 'DONE'};
    case types.logOutRequest().type:
      return {...state, status: 'PENDING'};
    case types.logOutSuccess().type:
      return {...state, status: 'DONE', userData: null};
    case types.logOutFail().type:
      return {...state, status: 'DONE'};
    case types.refreshRequest().type:
      return {...state, status: 'PENDING'};
    case types.refreshSuccess().type:
      return {...state, status: 'DONE', userData: payload.data.data};
    case types.refreshFail().type:
      return {...state, status: 'DONE'};
    default:
      return state;
  }
};

export default auth;
