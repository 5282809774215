import {takeEvery, put, fork, all, call} from 'redux-saga/effects';
import * as types from 'redux/actions/flightActions';
import {
  GetFlightList,
  GetFlightCheckList,
  GetFlightTrainList,
  SaveFlightTrain
} from 'api';

function* getFlightList(action) {
  try {
    const res = yield call(GetFlightList, action.data);
    yield put({type: types.getFlightListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getFlightListFail().type, err});
  }
}

function* getFlightCheckList(action) {
  try {
    const res = yield call(GetFlightCheckList, action.data);
    yield put({type: types.getFlightCheckListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getFlightCheckListFail().type, err});
  }
}

function* getFlightTrainList(action) {
  try {
    const res = yield call(GetFlightTrainList, action.data);
    yield put({type: types.getFlightTrainListSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.getFlightTrainListFail().type, err});
  }
}

function* saveFlightTrain(action) {
  try {
    const res = yield call(SaveFlightTrain, action.data);
    yield put({type: types.saveFlightTrainSuccess().type, payload: res});
  } catch (err) {
    yield put({type: types.saveFlightTrainFail().type, err});
  }
}

function* watchGetFlightList() {
  yield takeEvery(types.getFlightListRequest().type, getFlightList);
}

function* watchGetFlightCheckList() {
  yield takeEvery(types.getFlightCheckListRequest().type, getFlightCheckList);
}

function* watchGetFlightTrainList() {
  yield takeEvery(types.getFlightTrainListRequest().type, getFlightTrainList);
}

function* watchSaveFlightTrain() {
  yield takeEvery(types.saveFlightTrainRequest().type, saveFlightTrain);
}

export default function* rootSage() {
  yield all([
    fork(watchGetFlightList),
    fork(watchGetFlightCheckList),
    fork(watchGetFlightTrainList),
    fork(watchSaveFlightTrain),
  ]);
}
