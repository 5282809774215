import axios from 'axios';

const config = {
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    // baseURL: "/",
    timeout: 6000000,
    crossDomain: true,
    withCredentials: true,
  },
  instance = axios.create(config);
instance.interceptors.response.use(
  (response) => {
    const {data} = response;
    if (data && data.error) {

    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
//계정
export const Login = (params) => {
  return instance.post('/auth/login', params)
    .then((res) => {
      const {data} = res.data;
      if (data) {
        // instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken
      }
      return res;
    })
}
export const Refresh = (params) => {
  return instance.post('/auth/refresh', params)
    .then((res) => {
      const {data} = res.data;
      if (data) {
        // instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken
      }
      return res;
    })
}
export const LogOut = () => instance.post('/auth/logout');

//공통
export const GetAgcyList = (params) => instance.get('/common/agcy', {params});
export const GetTypeList = (params) => instance.post('/web/type', params);

//강사관리
export const CreateInstructor = (params) => instance.post('/web/instructor', params);
export const UpdateInstructor = (params) => instance.patch('/web/instructor', params);
export const GetInstructorList = (params) => instance.get('/web/instructor', {params});

//수강생관리
export const GetStudentList = (params) => instance.get('/web/student', {params});
export const GetFlightCareerList = (params) => instance.get('/web/student/career', {params});

//월정기점검
export const GetInspectionList = (params) => instance.get('/web/inspection', {params});
export const CreateInspection = (params) => instance.post('/web/inspection', params);
export const UpdateInspection = (params) => instance.patch('/web/inspection', params);

//비행현황
export const GetFlightList = (params) => instance.get('/web/flight', {params});
export const GetFlightCheckList = (params) => instance.post('/web/flight/checklist', params);
export const GetFlightTrainList = (params) => instance.get('/web/flight/trainlist', {params});
export const SaveFlightTrain = (params) => instance.post('/web/flight/train', params);

//기체관리
export const GetDroneList = (params) => instance.get('/web/drone', {params});
export const CreateDrone = (params) => instance.post('/web/drone', params);
export const UpdateDrone = (params) => instance.patch('/web/drone', params);
export const GetFlightLogList = (params) => instance.get('/web/drone/log', {params});
