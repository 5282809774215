import Logo from 'assets/menu_logo.png';
import classNames from 'classnames/bind';
import styles from 'styles/components/Menu.module.scss';
import {
  MdExpandMore,
  MdMonitor,
  MdBuild,
  MdInfo,
  MdOutlineMood,
  MdSupervisorAccount,
  MdFlight
} from 'react-icons/md';
import {useNavigate, useLocation} from 'react-router-dom';

const cx = classNames.bind(styles);
const menuData = [{
  path: '/',
  title: '기초정보',
  icon: <MdInfo/>,
  subMenu: [{
    path: '/',
    title: '강사등록',
    icon: <MdOutlineMood/>
  }, {
    path: '/student',
    title: '수강생 현황',
    icon: <MdSupervisorAccount/>
  }, {
    path: '/drone',
    title: '기체관리',
    icon: <MdFlight/>
  }]
}, {
  path: '/flight',
  title: '비행현황',
  icon: <MdMonitor/>
}, {
  path: '/inspection',
  title: '월 정기 점검',
  icon: <MdBuild/>
}]
export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const moveTo = (key,title) => {
    navigate(key, {state: { title}});
  };
  const selectCheck = (path, subMenu) => {
    if (subMenu) {
      const pathArr = subMenu.map((itm) => itm.path);
      return pathArr.includes(location.pathname);
    } else {
      return path === location.pathname
    }
  }
  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        <img src={Logo} alt={"로고"}/>
        <h3>A d m i n i s t r a t o r</h3>
      </div>
      <div className={cx('menu_wrap')}>
        {menuData.map((itm, i) => (
          <div
            key={i}
            className={cx('menu')}
          >
            <div
              className={cx([
                'main_menu',
                {
                  selected: selectCheck(itm.path, itm.subMenu),
                },
              ])}
              onClick={() => moveTo(itm.path, itm.title)}
            >
              <div className={cx('title')}>
                {itm.icon}
                <span>{itm.title}</span>
              </div>
              {itm.subMenu &&
              <MdExpandMore className={cx({isOpen: selectCheck(itm.path, itm.subMenu)})}/>
              }
            </div>
            {itm.subMenu && itm.subMenu.length > 0 &&
            <div className={cx(['sub_menu', {isOpen: selectCheck(itm.path, itm.subMenu)}])}>
              {itm.subMenu.map((con, ii) => (
                <div
                  key={ii}
                  onClick={() => moveTo(con.path, `${itm.title} > ${con.title}`)}
                  className={cx({selected: con.path === location.pathname})}
                >
                  {con.icon}
                  <span>{con.title}</span>
                </div>
              ))}
            </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
}
