export const getStudentListRequest = (params) => {
  return {
    type: 'GET_STUDENT_LIST_REQUEST',
    data: params,
  };
};
export const getStudentListSuccess = (data) => {
  return {
    type: 'GET_STUDENT_LIST_SUCCESS',
    data
  };
};
export const getStudentListFail = (error) => {
  return {
    type: 'GET_STUDENT_LIST_FAIL',
    error,
  };
};

export const getFlightCareerRequest = (params) => {
  return {
    type: 'GET_FLIGHT_CAREER_REQUEST',
    data: params,
  };
};

export const getFlightCareerSuccess = (data) => {
  return {
    type: 'GET_FLIGHT_CAREER_SUCCESS',
    data
  };
};

export const getFlightCareerFail = (error) => {
  return {
    type: 'GET_FLIGHT_CAREER_FAIL',
    error,
  };
};

export const resetStudent = () => {
  return {
    type: 'RESET_STUDENT',
  };
};

