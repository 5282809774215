import {combineReducers} from 'redux';
import auth from 'redux/reducers/auth';
import instructor from 'redux/reducers/instructor';
import common from 'redux/reducers/common';
import student from 'redux/reducers/student';
import inspection from 'redux/reducers/inspection';
import flight from 'redux/reducers/flight';
import drone from 'redux/reducers/drone';

const rootReducer = combineReducers({
  auth,
  instructor,
  student,
  inspection,
  flight,
  drone,
  common
});

export default rootReducer;
