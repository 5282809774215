export const getAgcyListRequest = (params) => {
  return {
    type: 'GET_AGCY_LIST_REQUEST',
    data: params,
  };
};

export const getAgcyListSuccess = (data) => {
  return {
    type: 'GET_AGCY_LIST_SUCCESS',
    data
  };
};

export const getAgcyListFail = (error) => {
  return {
    type: 'GET_AGCY_LIST_FAIL',
    error,
  };
};

export const getTypeListRequest = (params) => {
  return {
    type: 'GET_TYPE_LIST_REQUEST',
    data: params,
  };
};

export const getTypeListSuccess = (data) => {
  return {
    type: 'GET_TYPE_LIST_SUCCESS',
    data
  };
};

export const getTypeListFail = (error) => {
  return {
    type: 'GET_TYPE_LIST_FAIL',
    error,
  };
};

